<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Sms Şablonları</template>
      <template #content>
        <div class="top-button-area">
          <Button
            icon="pi pi-plus "
            class="p-button-sm p-mr-2 p-mb-2 yeni"
            label="Yeni"
            @click="_SmsSablonModal()"
          />
        </div>
        <DataTable
          v-if="DataTable_render"
          :columns="columns"
          :data="SmsSablonListesi"
          v-on:set-child-data="getDataChild"
        />
      </template>
    </Card>

    <Dialog
      v-model:visible="SmsSablonModal"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <h4>Sms Şablon</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12">
          <span for="spanSmsSablonAd">Adı</span>
          <InputText
            type="text"
            v-model="v$.SmsSablonAd.$model"
            aria-labelledby="spanSmsSablonAd"
            :class="{ 'p-invalid': v$.SmsSablonAd.$invalid && submitted }"
          />
          <small
            v-if="
              (v$.SmsSablonAd.$invalid && submitted) || v$.SmsSablonAd.$pending.$response
            "
            class="p-error"
            >{{ v$.SmsSablonAd.required.$message.replace("Value", "Name") }}</small
          >
        </div>
        <div class="p-field p-col-12">
          <span for="spanSmsSablonTanim">İçerik</span>
          <Textarea v-model="v$.SmsSablonIcerik.$model" rows="3" cols="30"
            :class="{ 'p-invalid': v$.SmsSablonIcerik.$invalid && submitted }" />
          <small
            v-if="
              (v$.SmsSablonIcerik.$invalid && submitted) ||
              v$.SmsSablonIcerik.$pending.$response
            "
            class="p-error"
            >{{
              v$.SmsSablonIcerik.required.$message.replace("Value", "Name")
            }}</small
          >
        </div>
      </div>

      <template #footer>
        <Button
          label="Tamam"
          icon="pi pi-check"
          class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)"
          autofocus
        />
        <Button
          label="Kapat"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="this._SmsSablonModal()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      SmsSablonListesi: [],
      columns: [
        { field: "Ad", header: " Adı" },
        { field: "Icerik", header: "Icerik" },
      ],
      SmsSablonModal: false,
      SmsSablonModelDurum: null,

      SmsSablonAd: null,
      SmsSablonIcerik: null,
      SmsSablon_rowid: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.SmsSablonModal = !this.SmsSablonModal;
      this.SmsSablonModelDurum = false;
      this.SmsSablon_rowid = getdata_child.data.SmsSablon_rowid;
      let data = {
        SmsSablon_rowid: getdata_child.data.SmsSablon_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetSmsSablonInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.SmsSablonAd.$model = res.data[0].Ad;
          this.v$.SmsSablonIcerik.$model = res.data[0].Icerik;
          this.SmsSablonKod = res.data[0].SmsSablon_rowid;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _SmsSablonModal() {
      this.SmsSablonModelDurum = true;
      this.SmsSablonModal = !this.SmsSablonModal;
      if (!this.SmsSablonModal) {
        // this.resetForm();
      }
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          SmsSablon_rowid: this.SmsSablon_rowid,
          Ad: this.SmsSablonAd,
          Icerik: this.SmsSablonIcerik,
        };
        if (this.SmsSablonModelDurum) {
          // Yeni Kullanıcı
          GlobalServis.GlobalServis("POST", "SetSmsSablon", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "SmsSablon oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this._SmsSablonModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          // Edit SmsSablon
          GlobalServis.GlobalServis("POST", "UpdateSmsSablon", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "SmsSablon düzenlemiştir",
                life: 3000,
              });
              this.Refresh();
              this._SmsSablonModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetSmsSablonTablosu", "").then((res) => {
        if (res.status == 200) {
          this.SmsSablonListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      SmsSablonAd: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SmsSablonIcerik: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>../../../../services/GlobalServis
